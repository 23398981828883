import React from "react";
import styles from "assets/styles.module.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Menu2 } from "tabler-icons-react";
import Credit from "./Credit";
import { DrawerContext } from "context/DrawerContext";
import useGetAssurance from "hooks/useGetAssurance";
import { useSelector } from "react-redux";
import { getParentForm } from "redux/features/parentSlice";

function PageHeader({ title, path, canShow = true }) {
  const navigation = useNavigate();
  const { handleOpen } = React.useContext(DrawerContext);

  const parent = useSelector(getParentForm);

  const app = useGetAssurance();

  return (
    <div className={styles.pageHeader}>
      <ArrowLeft
        style={{
          cursor: "pointer",
        }}
        size={29}
        onClick={() => navigation(path ?? -1)}
      />
      {parent?.type === "CONFIRMED" ? null : canShow ? (
        <h3 className="text-md font-bold">{"Démo Test"}</h3>
      ) : (
        <h3 className="text-md font-bold">{title ?? "Démo Test"}</h3>
      )}

      {canShow ? (
        <div className="flex items-center gap-2">
          {app === "Senassurance" && parent?.type === "CONFIRMED" ? null : (
            <Credit />
          )}
          <div
            className="p-2 cursor-pointer hover:text-[#999]"
            onClick={handleOpen}
          >
            <Menu2 />
          </div>
        </div>
      ) : (
        <div className=""></div>
      )}
    </div>
  );
}

export default PageHeader;
