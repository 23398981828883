import React from "react";

export function AudioGraphAnimation() {
  return (
    <div className="flex items-end justify-center h-5">
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar1"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar2"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar3"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar4"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar5"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar6"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar7"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar8"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar9"
      ></div>
      <div
        id="recordingBars"
        className="recording-bars mt-4 bar-name bar10"
      ></div>
    </div>
  );
}
