import React, { useState, useEffect, useCallback } from "react";
import {
  PlayerPause,
  PlayerPlay,
  // PlayerTrackNext,
  // PlayerTrackPrev,
} from "tabler-icons-react";
import TextToSpeechTest from "./TextToSpeachTest";

const TextToSpeech = ({
  text,
  onChange,
  textRecorded,
  current,
  handleValidate,
}) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const [value, setValue] = useState("");

  // useEffect(() => {
  //   const synth = window.speechSynthesis;
  //   const u = new SpeechSynthesisUtterance("Pas de consigne");
  //   const voices = window.speechSynthesis.getVoices();
  //   u.voice = voices[6];
  //   u.volume = 1;
  //   u.rate = 0.8;
  //   u.pitch = 0.7;
  //   u.lang = "fr";

  //   setUtterance(u);

  //   return () => {
  //     synth.cancel();
  //   };
  // }, []);

  // useEffect(() => {
  //   if (utterance) {
  //     utterance.text = getCurrentWord();
  //     handlePlay();
  //   }

  //   // console.log("lala");

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentWordIndex]);

  useEffect(() => {
    setValue("");
    setCurrentWordIndex(0);
  }, [current]);

  const speakText = () => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(getCurrentWord());
      utterance.lang = 'fr-FR'; // Définir la langue en français
      utterance.rate = 0.6
      utterance.pitch = 1
      window.speechSynthesis.speak(utterance);
    } else {
      alert('Sorry, your browser does not support text to speech!');
    }
  };

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    }
    utterance.text = getCurrentWord();

    synth.speak(utterance);

    setIsPaused(false);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setIsPaused(false);
  };

  const getCurrentWord = () => {
    const words = text?.includes("-") ? text.split("-") : text.split(" ");
    return words[currentWordIndex] ?? "";
  };

  const handleNextWord = () => {
    if (currentWordIndex < text.split("-").length) {
      setCurrentWordIndex((prevIndex) => prevIndex + 1);
      handleValidate({ id: currentWordIndex, value });

      setValue("");
    } else {
      // Fin de la lecture de tous les mots
      handleStop();
    }
  };

  const handlePrevWord = () => {
    if (currentWordIndex > 0) {
      setCurrentWordIndex((prevIndex) => prevIndex - 1);
    } else {
      // Fin de la lecture de tous les mots
      handleStop();
    }
  };

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
    // onChange(e.target.value);
    // setCurrentWordIndex(0);
  }, []);

  return (
    <>
      <div className="flex items-center justify-around mb-10 mt-2 z-20">
        <button
          disabled={currentWordIndex <= 0}
          onClick={handlePrevWord}
          className="bg-primary rounded-full py-2 px-4 text-white cursor-pointer disabled:opacity-40"
        >
          {/* <PlayerTrackPrev size={20} /> */}
          Retour
        </button>
        {/* <div
          onClick={isPaused ? handleStop : handlePlay}
          className="bg-primary rounded-full p-6 text-white cursor-pointer"
        >
          {isPaused ? <PlayerPause size={20} /> : <PlayerPlay size={20} />}
        </div> */}
         <button
          onClick={speakText}
          className="bg-primary rounded-full p-6 text-white cursor-pointer"
        >
          {isPaused ? <PlayerPause size={20} /> : <PlayerPlay size={20} />}
        </button>
        <button
          onClick={handleNextWord}
          disabled={currentWordIndex === text.split("-")?.length}
          className="bg-primary rounded-full py-2 px-4 text-white cursor-pointer disabled:opacity-40"
        >
          {/* <PlayerTrackNext size={20} /> */}
          Suivant
        </button>
      </div>
      <div className="w-full p-2">
        <input
          type="text"
          name=""
          id=""
          className="border-[1px] border-gray w-full p-2 rounded-lg outline-none text-center text-2xl h-[120px]"
          onChange={handleChange}
          placeholder="Ecrire ici"
          // value={textRecorded ?? ""}
          value={value}
        />
        {/* <button onClick={handleNextWord}>Mot suivant</button> */}
      </div>
    </>
  );
};

export default TextToSpeech;
