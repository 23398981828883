const { createSlice } = require("@reduxjs/toolkit");

const useSlice = createSlice({
  name: "userInfo",
  initialState: {
    onBoarding: false,
    userInfo: {
      status: null,
      userId: 1340,
      schoolId: null,
      classeId: null,
      csrfToken: null,
    },
    student: {
      firstName: "Enfant",
      lastName: "Test",
      id: 1853,
      classe: {
        id: 17,
        students_count: 1,
        name: "HV3eme",
        archived: false,
      },
      school: {
        id: 6,
        ville: {
          id: 1,
          name: "Ecole Police - Dakar",
        },
        directeur: null,
        name: "[TEST]Genimi",
        archived: false,
        villeId: 1,
        directeurId: null,
      },
    },
    currentDiagnostiqueType: null,
    appelOffline: { data: [] },
    elevePositionnement: null,
    niveauPositionnement: null,
  },
  reducers: {
    setOnBoarding(state) {
      state.onBoarding = true;
    },
    addUserId: (state, { payload }) => {
      state.userInfo.userId = payload;
    },
    addUserStatus: (state, { payload }) => {
      state.userInfo.status = payload;
    },
    addSchoolId: (state, { payload }) => {
      state.userInfo.schoolId = payload;
    },
    addClasseId: (state, { payload }) => {
      state.userInfo.classeId = payload;
    },
    addCsrfToken: (state, { payload }) => {
      state.userInfo.csrfToken = payload;
    },
    addCurrentDiagnostique: (state, { payload }) => {
      state.currentDiagnostiqueType = payload;
    },
    clearUserInfo: (state) => {
      state.userInfo.userId = 1340;
      state.userInfo.schoolId = null;
      state.userInfo.classeId = null;
      state.userInfo.csrfToken = null;
      state.currentDiagnostiqueType = null;
    },

    // For appel offline
    addAppelOffline: (state, { payload }) => {
      if (
        state.appelOffline.data?.find(
          (element) => element.studentId === payload.studentId
        )
      ) {
        state.appelOffline.data = state.appelOffline.data.map((element) => {
          if (element.studentId === payload.studentId) {
            return { ...element, status: payload.status };
          } else {
            return { ...element };
          }
        });
      } else {
        state.appelOffline.data.push(payload);
      }
    },

    //For nineau testDePositionnement
    setNiveauPositionnement: (state, { payload }) => {
      state.niveauPositionnement = payload;
    },

    //For student testDePositionnement
    setElevePositionnement: (state, { payload }) => {
      state.elevePositionnement = payload;
    },

    //Clear info appel offline
    clearAppelOffline: (state) => {
      state.appelOffline.data = [];
    },

    setStudent(state) {
      state.student = {
        firstName: "Enfant",
        lastName: "Test",
        id: 1853,
        classe: {
          id: 17,
          students_count: 1,
          name: "HV3eme",
          archived: false,
        },
        school: {
          id: 6,
          ville: {
            id: 1,
            name: "Ecole Police - Dakar",
          },
          directeur: null,
          name: "[TEST]Genimi",
          archived: false,
          villeId: 1,
          directeurId: null,
        },
      };
    },
  },
});

export const getOnBoardingState = (state) =>
  state?.user?.userReducer?.onBoarding;
export const getUserId = (state) => state?.user?.userReducer?.userInfo?.userId;
export const getStudent = (state) => state?.user?.userReducer?.student;
export const getUserStatus = (state) =>
  state?.user?.userReducer?.userInfo?.status;
export const getSchollId = (state) =>
  state?.user?.userReducer?.userInfo?.schoolId;
export const getClasseId = (state) =>
  state?.user?.userReducer?.userInfo?.classeId;
export const getCsrfToken = (state) =>
  state?.user?.userReducer?.userInfo?.csrfToken;
export const getCurrentDiagnostique = (state) =>
  state?.user?.userReducer?.currentDiagnostiqueType;
export const getElevePositionnement = (state) =>
  state?.user?.userReducer?.elevePositionnement;
export const getAppelsOfflineData = (state) =>
  state?.user?.userReducer?.appelOffline.data;

export const {
  setOnBoarding,
  addUserId,
  addUserStatus,
  addSchoolId,
  addClasseId,
  addCsrfToken,
  setStudent,
  addCurrentDiagnostique,
  clearUserInfo,
  addAppelOffline,
  setNiveauPositionnement,
  setElevePositionnement,
  clearAppelOffline,
} = useSlice.actions;
export default useSlice.reducer;
