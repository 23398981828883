import React from "react";
import styles from "../assets/styles.module.css";
import HacFlex from "./HacFlex";

function HacButton({
  children,
  type,
  bg,
  color,
  h,
  w,
  radius,
  onClick,
  style,
  isLoading,
  icon,
  mt,
  mb,
  ml,
  mr,
  mx,
  my,
}) {
  return (
    <button
      style={{
        backgroundColor: bg,
        color: color ?? "#fff",
        height: h,
        width: w,
        ...style,
        marginTop: mt ?? my,
        marginBottom: mb ?? my,
        marginLeft: ml ?? mx,
        marginRight: mr ?? mx,
        borderRadius: radius,
      }}
      // className={styles.Button}
      className="w-full p-2 h-[35px]"
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      disabled={isLoading}
      type={type}
    >
      <HacFlex gap={5} position={"center"}>
        {isLoading && (
          <span className={styles.ButtonLoader} style={{ color: color }}></span>
        )}
        {!isLoading && icon && icon}
        {children}
      </HacFlex>
    </button>
  );
}

export default HacButton;
