import { useSelector } from "react-redux";
import {
  getClasseId,
  getCsrfToken,
  getCurrentDiagnostique,
  getElevePositionnement,
  getOnBoardingState,
  getSchollId,
  getUserId,
  getUserStatus,
} from "redux/features/userSlice";

function useGetUserInfo() {
  const onBoarding = useSelector(getOnBoardingState);
  const userId = useSelector(getUserId);
  const userStatus = useSelector(getUserStatus);
  const schoolId = useSelector(getSchollId);
  const csrfToken = useSelector(getCsrfToken);
  const diagnostiqueType = useSelector(getCurrentDiagnostique);
  const classeId = useSelector(getClasseId);
  const eleve = useSelector(getElevePositionnement);
  const eleveId = eleve?.id;

  return {
    onBoarding,
    userId,
    userStatus,
    eleveId,
    schoolId,
    classeId,
    csrfToken,
    diagnostiqueType,
  };
}

export default useGetUserInfo;
