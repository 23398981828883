import React from "react";

const Timeline = ({ data = {} }) => {
  return (
    <div className="bg-gray-100 p-2 rounded-lg">
      {/* <h2 className="text-xl font-semibold mb-4">Timeline des niveaux</h2> */}

      <div
        className="mb-4 p-2 border border-gray-300 rounded-lg text-center"
        style={{ borderColor: "rgba(0,0,0,0.2)" }}
      >
        <h3 className="text-md font-semibold">{data?.niveau}</h3>
        {/*   <ul className="mt-2 space-y-2">
         {level.data
              ? level.data.map((item, index) => (
                  <li key={index} className="flex items-center">
                    <span className="w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center font-bold mr-2">
                      ✔
                    </span>
                    {index}
                  </li>
                ))
              : null}
        </ul> */}
      </div>
    </div>
  );
};

export default Timeline;
