import React, { useEffect, useState } from "react";
import {
  Loader,
  Microphone,
  PlayerPause,
  PlayerPlay,
  Send,
  Trash,
} from "tabler-icons-react";
import { AudioGraphAnimation } from "./AudioGraphAnimation";
import MicRecorder from "mic-recorder-to-mp3";

const AudioRecording = ({
  data,
  onSelect,
  current,
  resetTranscription,
  onGetWordSelected,
}) => {
  const [curretWord, setCurrentWord] = useState(0);
  const [isLoading] = useState(false);
  const [selectionWord, setSelectionWord] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isReader, setReader] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audio, setAudio] = useState(null);
  const setFile_ = useState(null)[1];
  const [recorder] = useState(new MicRecorder({ bitRate: 128 }));

  useEffect(() => {
    setSelectionWord([]);
    setCurrentWord(0);
  }, [current]);

  const isRecordingSupported = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  const unlockAudioContext = () => {
    if (window.audioContext && window.audioContext.state === "suspended") {
      const silentBuffer = window.audioContext.createBuffer(1, 1, 22050);
      const source = window.audioContext.createBufferSource();
      source.buffer = silentBuffer;
      source.connect(window.audioContext.destination);
      source.start();
    }
  };

  const startRecording = () => {
    if (!window.audioContext) {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new window.AudioContext();
    }
    unlockAudioContext();
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
        setIsPaused(false);
      })
      .catch((e) => {
        console.error("Error starting recording:", e);
        // Handle the error (e.g., show a message to the user)
      });
  };

  const stopRecording = (stop = false) => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "audio.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        if (stop) {
          setFile_(file);
        }
        addAudioElement(file, stop);
        const audioURL = URL.createObjectURL(file);
        setAudio(audioURL);
        setIsRecording(false);
      })
      .catch((e) => {
        console.error("Error stopping recording:", e);
      });
  };

  const cancelRecording = () => {
    recorder.stop();
    setIsRecording(false);
    setIsPaused(false);
    setAudio(null);
  };

  const addAudioElement = async (file, stop) => {
    if (!file) {
      return;
    }

    setSelectionWord([
      ...selectionWord.filter((item) => item.id !== curretWord),
      {
        item: data?.correction_dp.join(" ").split("-")[curretWord],
        id: curretWord,
        file,
      },
    ]);
    onGetWordSelected([
      ...selectionWord.filter((item) => item.id !== curretWord),
      {
        item: data?.correction_dp.join(" ").split("-")[curretWord],
        id: curretWord,
        file,
      },
    ]);

    if (!stop) {
      setCurrentWord(
        data?.correction_dp.join(" ").split("-").length === curretWord + 1
          ? curretWord
          : curretWord + 1
      );
    }
  };

  return (
    <div className="mt-1">
      <div className="flex px-2 py-2">
        <p className="text-[1.8rem] font-bold py-5 flex w-full min-h-[150px] items-center justify-center rounded-lg px-[1rem] bg-[#9996]">
          {data?.correction_dp.join(" ").split("-")[curretWord]}
        </p>
      </div>
      <div className="">
        {isRecording || isReader ? (
          <div className="bg-secondary p-1 flex flex-col gap-3 py-2 text-[white] ">
            <div className="flex items-center gap-2 p-2">
              <div className="flex-1 flex justify-center">
                {isReader ? (
                  <div className="scale-90">
                    <audio src={audio} controls />
                  </div>
                ) : (
                  <AudioGraphAnimation />
                )}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="p-2 text-danger"
                onClick={() => {
                  setReader(false);
                  cancelRecording();
                }}
              >
                <Trash />
              </button>
              <button
                className="p-2"
                onClick={() => {
                  setReader(true);
                  setIsPaused(true);
                  stopRecording(true);
                }}
              >
                {isReader ? null : isPaused ? <PlayerPlay /> : <PlayerPause />}
              </button>
              <button
                className="bg-[green] p-3 text-[white] rounded-full"
                onClick={() => {
                  if (isPaused) {
                    setIsRecording(false);
                    setReader(false);
                    setIsPaused(false);
                    setAudio(null);
                    setCurrentWord(
                      data?.correction_dp.join(" ").split("-").length ===
                        curretWord + 1
                        ? curretWord
                        : curretWord + 1
                    );
                  } else {
                    stopRecording(false);
                  }
                }}
              >
                <Send />
              </button>
            </div>
          </div>
        ) : data?.correction_dp.join(" ").split("-")?.length ===
          selectionWord?.length ? null : (
          <div className="flex items-center  justify-center gap-4 p-1 mt-4">
            {isLoading ? (
              <div className="p-3">
                <Loader className="animate-spin" />
              </div>
            ) : isRecordingSupported() ? (
              <button
                className="bg-primary py-3 rounded-full px-5 text-white flex justify-center"
                onClick={startRecording}
              >
                Appuyer pour parler ⇢
                <Microphone
                  color={isRecording ? "red" : "#fff"}
                  className="animate-bounce"
                />
              </button>
            ) : (
              <p>Audio recording is not supported on this device.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioRecording;
