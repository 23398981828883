import React, { useState } from "react";
import AudioRecording from "./AudioRecording";
import Timeline from "components/Timeline";
import TextToSpeech from "./TextSpeach";
import { Header } from "./MathPage";
import KeyBoard from "components/KeyBoard";
import ProgressIndicator from "./ProgressIndicator";

function WordPage({
  data,
  currentLevel,
  current,
  selected,
  onTranscription,
  handleAddWord,
  handleRemoveWord,
  resetTranscription,
  onTextChange,
  textRecorded,
  randomeImage,
  eleve,
  studentDemo,
  handleGoBack,
  handleAddTextSpeachWord,
  selectedTextSpeach,
  onGetWordSelected,
  suggestionManquante,
  suggestionInputActive,
  onPressKebeard,
  onCheckInput,
}) {
  const setInputValue = useState("")[1];

  return (
    <div className="scale-x-95 h-[100dvh] flex flex-col justify-between">
      <div className="">
        <Header
          handleGoBack={handleGoBack}
          studentDemo={studentDemo}
          eleve={eleve}
          type={"Lecture"}
        />
        <div className="px-1">
          <Timeline data={data[currentLevel]} />
          <ProgressIndicator
            current={current}
            data={data[currentLevel]?.data}
            randomeImage={randomeImage}
            selected={selected}
            handleRemoveWord={handleRemoveWord}
            selectedTextSpeach={selectedTextSpeach}
            suggestionManquante={suggestionManquante}
            onCheckInput={onCheckInput}
            suggestionInputActive={suggestionInputActive}
          />
        </div>
      </div>

      <div className="px-1">
        {data[currentLevel]?.data[current]?.type === "organisation" ? (
          <div
            style={{
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
              marginBottom: 10,
            }}
          >
            {data[currentLevel]?.data[current]?.choices.map((item, index) => (
              <div
                className="bg-[#9993] px-3 py-1 rounded-lg cursor-pointer text-sm"
                key={index}
                onClick={() => handleAddWord(item + "-" + index)}
                style={{
                  opacity: selected.includes(item + "-" + index) ? 0.3 : 1,
                }}
              >
                {item}
              </div>
            ))}
          </div>
        ) : data[currentLevel]?.data[current]?.type === "lettreManquante" ? (
          suggestionInputActive !== null ? (
            <div className="mt-4">
              <KeyBoard onPress={onPressKebeard} canClear />
            </div>
          ) : null
        ) : data[currentLevel]?.data[current]?.type === "alphabet" ? (
          <>
            <div className="mb-2">
              <KeyBoard
                onPress={(value) => {
                  setInputValue(value);
                  handleAddWord(value.toUpperCase());
                }}
              />
            </div>
            <div></div>
          </>
        ) : data[currentLevel]?.data[current]?.type === "transcription" ? (
          <AudioRecording
            data={data[currentLevel]?.data[current]}
            current={current}
            onSelect={(value) => onTranscription(value)}
            resetTranscription={resetTranscription}
            onGetWordSelected={onGetWordSelected}
          />
        ) : data[currentLevel]?.data[current]?.type === "dictee" ? (
          <TextToSpeech
            text={data[currentLevel]?.data[current]?.correction_dp.join(" ")}
            onChange={onTextChange}
            handleValidate={handleAddTextSpeachWord}
            textRecorded={textRecorded}
            current={current}
          />
        ) : null}
      </div>
    </div>
  );
}

export default WordPage;
