import React, { useEffect, useState } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "redux/app/Store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "context/AuthContext";
import Root from "Root";
import { BrowserRouter } from "react-router-dom";
// import { DrawerProvider } from "context/DrawerContext";
import ModalInstall from "components/ModalInstall";
import { DrawerProvider } from "context/DrawerContext";
// import AlphabetExercises from "pages/home/components/tests/Alphabet";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleInstall = () => {
    setOpened(false);

    // Show the prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        console.log({ choiceResult });
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    // console.log({ env: process.env.REACT_APP_API_URL_PROD });
    async function actionInstall(e) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      setOpened(true);
      // Update UI to notify the user they can add to home screen
      // btnInstall.current.style.display = "block";
    }

    window.addEventListener("beforeinstallprompt", actionInstall);
    // return window.removeEventListener("beforeinstallprompt", actionInstall);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <div
              className="max-w-[500px] mx-auto overflow-hidden bg-red-300 relative"
              // style={{
              //   boxShadow: "1px 0 5px gray",
              // }}
            >
              <DrawerProvider>
                <Root />
                {/* <AlphabetExercises /> */}
                <ToastContainer
                  position="bottom-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <ModalInstall
                  opened={opened}
                  onInstall={handleInstall}
                  onClosed={() => setOpened(false)}
                />
              </DrawerProvider>
            </div>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
