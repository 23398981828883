import React, { useEffect, useMemo, useState } from "react";
import Button from "components/Button";
import { Template } from "layouts/Template";
import { MoodEmpty } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { Data } from "utils/data";
import Finish from "pages/home/components/Finish";
import {
  useAddDiagnostiqueEleveTentativeMutation,
  useGetEpreuvexByDiagnostiqueQuery,
} from "redux/features/diagnostiqueApi";
import useGetUserInfo from "hooks/useGetUserInfo";
import WordPage from "pages/home/components/WordPage";
import { useGetDiagnostiqueWithNiveauxQuery } from "redux/features/parentApi";
import useQuery from "components/useQuery";
import { useDispatch } from "react-redux";
import {
  setEpreuveNote,
  setEscoreNiveau,
} from "redux/features/diagnostiqueSlice";
import { useSelector } from "react-redux";
import { getNumber } from "utils/soted";
import {
  calculerNoteFinale,
  compareText,
  compareTextWithPosition,
  correctionLettreManquante,
  correctionLettreManquanteLength,
} from "utils/comparentText";
import { progressImage } from "utils/images";
import { useGetEleveQuery } from "redux/features/eleveApi";
import Loader from "components/Loader";
import { getCurrentEleve, setTentatives } from "redux/features/eleveSlice";
import axios from "axios";

const API_AUDIO = "https://tts.fewnu.app/";

async function sendElements(elements) {
  let result = [];
  for (const element of elements) {
    const item = { item: element?.item, id: element?.id };
    let score = 0;
    const formData = new FormData();
    formData.append("upload_file", element.file);

    try {
      const res = await axios.post(
        `${API_AUDIO}transcription?lang=fr`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res?.status === 200) {
        const scoreRes = await axios.post(
          `${API_AUDIO}compare_texts?source=${element?.item}&target=${res?.data}`,
          {}
        );

        if (scoreRes?.status === 200) {
          score =
            scoreRes?.data?.llm_score?.score > scoreRes?.data?.score
              ? scoreRes?.data?.llm_score?.score
              : scoreRes?.data?.score;
        } else {
          score = 0;
        }

        // Handle scoreRes as needed
        // console.log(scoreRes.data);
      }
    } catch (error) {
      console.error(`Error processing item ${element.id}:`, error);
      score = 0;
    }

    item.score = score;
    result.push(item);
  }

  return result;
}

function ExerciceLecturePage() {
  const params = useParams();
  const navigation = useNavigate();
  const searchParams = useQuery();

  const dispatch = useDispatch();

  const { csrfToken } = useGetUserInfo();

  const [data] = useState(
    Data[params.name].find((item) => item.id === parseInt(params.id))?.data
  );

  const diagnostiqueStore = useSelector(
    (state) => state?.user?.diagnostiqueReducer
  );

  const studentDemo = useSelector(getCurrentEleve);

  const { data: datas } = useGetEpreuvexByDiagnostiqueQuery(
    parseInt(params.id)
  );

  const { data: diagnostiqueNiveauxData, isLoading } =
    useGetDiagnostiqueWithNiveauxQuery(parseInt(params.id), {
      refetchOnMountOrArgChange: true,
    });

  const { data: eleve } = useGetEleveQuery(params?.eleveId);

  const [diagnostiqueNiveaux, setDiagnostiqueNiveaux] = useState({});

  const [addDiagnostiqueEleveTentative, { isLoading: addLoading }] =
    useAddDiagnostiqueEleveTentativeMutation();

  const [currentLevel, setCurrentLevel] = useState(
    parseInt(searchParams.get("niveau")) ?? 0
  );
  const [current, setCurrent] = useState(
    parseInt(searchParams.get("epreuve")) ?? 0
  );

  const [selected, setSelected] = useState([]);
  const [selectedTextSpeach, setSelectedTextSpeach] = useState([]);
  const [getWordSelected, setGetWordSelected] = useState([]);
  const [textRecorded, setTextRecorded] = useState("");
  const setTranscription = useState("")[1];
  const [end, setEnd] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isSendingLoading, setIsSendingLoading] = useState(false);

  const [suggestionManquante, setSuggestionManquante] = useState([]);
  const [suggestionInputActive, setSuggestionInputActive] = useState(null);

  /**
   *
   */
  useEffect(() => {
    // console.log("lall");
    if (
      diagnostiqueNiveaux?.data &&
      diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.type ===
        "lettreManquante"
    ) {
      // console.log("icicci");
      setSuggestionManquante([
        ...diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.correction_dp
          .map((item, key) =>
            diagnostiqueNiveaux?.data[currentLevel]?.data[
              current
            ]?.choices.find(
              (lettre) => lettre?.toLowerCase() === item?.toLowerCase()
            )
              ? { key, correction: item, choice: "" }
              : null
          )
          .filter((item) => item !== null),
      ]);
    }
  }, [current, currentLevel, diagnostiqueNiveaux?.data]);

  /**
   *
   * @param {*} value
   */
  const onPressKebeard = (value) => {
    if (suggestionInputActive !== null) {
      // console.log({ value, suggestionInputActive });
      setSuggestionManquante([
        ...suggestionManquante.filter(
          (item) => item?.key !== suggestionInputActive
        ),
        {
          ...suggestionManquante.find(
            (item) => item?.key === suggestionInputActive
          ),
          choice: value,
        },
      ]);
      if (
        suggestionManquante[
          suggestionManquante.findIndex(
            (item) => item?.key === suggestionInputActive
          ) + 1
        ]?.key
      ) {
        setSuggestionInputActive(
          suggestionManquante[
            suggestionManquante.findIndex(
              (item) => item?.key === suggestionInputActive
            ) + 1
          ]?.key
        );
      }
    }
  };

  /**
   *
   * @param {*} value
   */
  const handleAddWord = (value) => {
    !selected.includes(value)
      ? setSelected([...selected, value])
      : setSelected(selected.filter((item) => item !== value));
  };

  /**
   *
   * @param {*} value
   */
  const handleRemoveWord = (value) => {
    selected.includes(value) &&
      setSelected(selected.filter((item) => item !== value));
  };

  const handleAddTextSpeachWord = (value) => {
    // console.log({ value });
    setSelectedTextSpeach((prev) => [
      ...prev.filter((item) => item?.id !== value?.id),
      { ...value },
    ]);
  };

  /**
   *
   */
  const handleNextExercice = async () => {
    let note = 0;
    setIsSendingLoading(true);
    if (
      diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.type ===
      "transcription"
    ) {
      // note = compareTextWithPosition(
      //   getWordSelected
      //     .map((item) => item?.item)
      //     .join(" ")
      //     .toLowerCase(),
      //   diagnostiqueNiveaux?.data[currentLevel]?.data[current].correction_dp
      //     .join(" ")
      //     .replaceAll(" -", "-")
      //     .replaceAll(" - ", "-")
      //     .replaceAll("- ", "-")
      //     .split("-")
      //     .join(" ")
      //     .toLowerCase(),
      //   diagnostiqueNiveaux?.data[currentLevel]?.data[current].bareme
      // )?.score;

      const audioResult = await sendElements(getWordSelected);

      // console.log({ getWordSelected, result });

      // return;
      note = calculerNoteFinale(
        audioResult,
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].bareme
      );
    } else if (
      diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.type ===
      "organisation"
    ) {
      note = compareTextWithPosition(
        selected
          ?.map((item) => item?.split("-")[0])
          .join(" ")
          .toLowerCase(),
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].correction_dp
          .join(" ")
          .toLowerCase(),
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].bareme
      )?.score;
    } else if (
      diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.type ===
      "lettreManquante"
    ) {
      note = correctionLettreManquante(
        suggestionManquante,
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].bareme
      );
    } else if (
      diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.type === "dictee"
    ) {
      note = compareTextWithPosition(
        selectedTextSpeach
          .map((item) => item.value)
          .join(" ")
          .toLowerCase(),
        diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.correction_dp
          .join(" ")
          .replaceAll(" -", "-")
          .replaceAll(" - ", "-")
          .replaceAll("- ", "-")
          .split("-")
          .join(" ")
          .toLowerCase(),
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].bareme
      )?.score;
    } else {
      note = compareText(
        selected.join(" ").toLowerCase(),
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].correction_dp
          .join(" ")
          .toLowerCase(),
        diagnostiqueNiveaux?.data[currentLevel]?.data[current].bareme
      )?.score;
    }

    try {
      // return console.log({ note });
      dispatch(setEscoreNiveau(note));
      dispatch(
        setEpreuveNote({
          epreuveId: diagnostiqueNiveaux?.data[currentLevel]?.data[current]?.id,
          // is_correct: iscorrect,
          note: Math.round(note),
          niveau: diagnostiqueNiveaux?.data[currentLevel]?.niveau,
        })
      );

      // console.log(res);

      setTextRecorded("");
      setOpened(false);
      setSelected([]);
      setSelectedTextSpeach([]);
      setGetWordSelected([]);
      setTranscription("");
      setSuggestionManquante([]);
      setSuggestionInputActive(null);

      const currentEpreuve =
        current + 1 === diagnostiqueNiveaux?.data[currentLevel]?.data.length
          ? current
          : current + 1;
      navigation(
        `?niveau=${currentLevel}&epreuve=${currentEpreuve}`,
        current + 1 === diagnostiqueNiveaux?.data[currentLevel]?.data.length
          ? { state: { end: true }, replace: true }
          : { replace: true }
      );

      if (current + 1 === diagnostiqueNiveaux?.data[currentLevel]?.data.length)
        setEnd(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSendingLoading(false);
    }
  };

  /**
   *
   */
  useMemo(() => {
    if (diagnostiqueNiveauxData) {
      setDiagnostiqueNiveaux({
        ...diagnostiqueNiveaux,
        count: diagnostiqueNiveauxData?.count,
        data: [...diagnostiqueNiveauxData?.data].sort(
          (a, b) => getNumber(a.niveau) - getNumber(b.niveau)
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnostiqueNiveauxData]);

  /**
   *
   */
  useMemo(() => {
    setCurrentLevel(parseInt(searchParams.get("niveau")));
    setCurrent(parseInt(searchParams.get("epreuve")));
  }, [searchParams]);

  /**
   *
   * @returns
   */
  const handleContinueNextLevel = async () => {
    if (
      diagnostiqueNiveaux?.data.length === currentLevel + 1 ||
      diagnostiqueStore?.notes
        .filter(
          (note) =>
            note?.niveau === diagnostiqueNiveaux?.data[currentLevel]?.niveau
        )
        .reduce((acc, curr) => acc + curr?.note, 0) <
        diagnostiqueNiveaux?.data[currentLevel]?.seuil
    ) {
      try {
        const { score: scoreNiveau, ...res } = diagnostiqueStore;

        const notes = res?.notes.map((note) => ({
          epreuveId: note?.epreuveId,
          note: note?.note,
        }));

        // return console.log({ ...res, notes, status: "VALIDE" });

        const response = await addDiagnostiqueEleveTentative({
          body: {
            ...res,
            notes,
            status: "VALIDE",
            diagnosticId: parseInt(params.id),
          },
          token: csrfToken,
        });
        if (!response?.error) {
          setEnd(false);

          dispatch(
            setTentatives({
              diagnostique: diagnostiqueStore,
              eleveId: studentDemo?.id,
              diagnosticId: response?.data?.id,
              type: "LECTURE",
              createdAt: new Date(Date.now()),
            })
          );

          navigation(`../analyse/${response?.data?.id}`, {
            replace: true,
          });
          return;
        }
        console.log({ response });
        // setEnd(false);
        // console.log({ response });
        // navigation("/" + params?.eleveId + "/matiere", {
        //   replace: true,
        // });

        // console.log({ response });

        // dispatch(
        //   setTentatives({
        //     diagnostique: diagnostiqueStore,
        //     eleveId: studentDemo?.id,
        //     // eleveId: params?.eleveId,
        //     diagnosticId: response?.data?.id,
        //     type: "LECTURE",
        //     createdAt: new Date(Date.now()),
        //   })
        // );

        // console.log({
        //   diagnostic: {
        //     diagnostique: diagnostiqueStore,
        //     eleveId: studentDemo?.id,
        //     diagnosticId: response?.data?.id,
        //     type: "LECTURE",
        //     createdAt: new Date(Date.now()),
        //   },
        // });

        // navigation("../analyse/" + response?.data?.id, {
        //   replace: true,
        //   state: {
        //     diagnostiqueId: params?.id,
        //   },
        // });
      } catch (error) {
        console.log({ error });
      }
    } else {
      setEnd(false);
      navigation(`?niveau=${currentLevel + 1}&epreuve=${0}`, {
        replace: true,
      });
    }
  };

  /**
   *
   */
  const handleQuit = async () => {
    try {
      await addDiagnostiqueEleveTentative({
        body: { ...diagnostiqueStore },
        token: csrfToken,
      });
      // console.log({ res });
      navigation("/" + params?.eleveId + "/matiere", {
        replace: true,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  return isLoading ? (
    <div className="h-[100dvh] w-full flex flex-col justify-center items-center">
      <Loader />
      <p>Chargement des exercices...</p>
    </div>
  ) : diagnostiqueNiveauxData?.count === 0 ? (
    <div className="h-[100dvh] w-full flex justify-center items-center flex-col gap-4">
      <p>Aucune question pour le moment</p>
      <Button radius={"md"} onClick={() => navigation(-1)}>
        retour
      </Button>
    </div>
  ) : (
    <Template
      footerStyle={{ height: 70 }}
      footer={
        isLoading || end ? null : diagnostiqueNiveaux?.data &&
          (textRecorded ||
            selected.length ===
              diagnostiqueNiveaux?.data[currentLevel]?.data[current]
                .correction_dp?.length ||
            getWordSelected.length ===
              diagnostiqueNiveaux?.data[currentLevel]?.data[
                current
              ]?.correction_dp
                .join(" ")
                .replaceAll(" -", "-")
                .replaceAll(" - ", "-")
                .replaceAll("- ", "-")
                .split("-").length ||
            selectedTextSpeach.length ===
              diagnostiqueNiveaux?.data[currentLevel]?.data[
                current
              ]?.correction_dp
                .join(" ")
                .replaceAll(" -", "-")
                .replaceAll(" - ", "-")
                .replaceAll("- ", "-")
                .split("-").length ||
            correctionLettreManquanteLength(suggestionManquante)) ? (
          <div
            style={{
              display: "flex",
              gap: 10,
              padding: "0 10px",
              marginTop: 5,
            }}
          >
            {diagnostiqueNiveaux?.acount === 0 ? (
              <Button
                onClick={() => navigation(-1, { replace: true })}
                style={{ width: "100%", height: 40, marginTop: 10 }}
                radius={"md"}
              >
                Retour
              </Button>
            ) : !end &&
              diagnostiqueNiveaux?.data &&
              (textRecorded ||
                selected.length > 0 ||
                getWordSelected.length ===
                  diagnostiqueNiveaux?.data[currentLevel]?.data[
                    current
                  ]?.correction_dp
                    .join(" ")
                    .replaceAll(" -", "-")
                    .replaceAll(" - ", "-")
                    .replaceAll("- ", "-")
                    .split("-").length ||
                selectedTextSpeach.length ===
                  diagnostiqueNiveaux?.data[currentLevel]?.data[
                    current
                  ]?.correction_dp
                    .join(" ")
                    .replaceAll(" -", "-")
                    .replaceAll(" - ", "-")
                    .replaceAll("- ", "-")
                    .split("-").length ||
                correctionLettreManquanteLength(suggestionManquante)) ? (
              <Button
                onClick={handleNextExercice}
                style={{ width: "100%", height: 40, marginTop: 10 }}
                radius={"md"}
                disabled={
                  textRecorded ||
                  selected.length > 0 ||
                  getWordSelected.length ===
                    diagnostiqueNiveaux?.data[currentLevel]?.data[
                      current
                    ]?.correction_dp
                      .join(" ")
                      .replaceAll(" -", "-")
                      .replaceAll(" - ", "-")
                      .replaceAll("- ", "-")
                      .split("-").length ||
                  selectedTextSpeach.length ===
                    diagnostiqueNiveaux?.data[currentLevel]?.data[
                      current
                    ]?.correction_dp
                      .join(" ")
                      .replaceAll(" -", "-")
                      .replaceAll(" - ", "-")
                      .replaceAll("- ", "-")
                      .split("-").length ||
                  correctionLettreManquanteLength(suggestionManquante)
                    ? false
                    : true
                }
                loading={isSendingLoading}
              >
                Valider
              </Button>
            ) : null}
          </div>
        ) : null
      }
      // style={{ userSelect: "none" }}
    >
      {/* Word quizz */}
      {datas?.count === 0 ? (
        "Pas d'exercice"
      ) : end ? (
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {isLoading || !diagnostiqueNiveaux?.data ? (
            <Loader />
          ) : (
            <Finish
              data={diagnostiqueNiveaux?.data[currentLevel]}
              globalNote={diagnostiqueNiveaux?.data[currentLevel]?.data?.reduce(
                (acc, cur) => acc + cur?.bareme,
                0
              )}
              score={diagnostiqueStore?.notes
                .filter(
                  (note) =>
                    note?.niveau ===
                    diagnostiqueNiveaux?.data[currentLevel]?.niveau
                )
                .reduce((acc, curr) => acc + curr?.note, 0)}
              note={diagnostiqueNiveaux?.data[currentLevel]?.data?.length}
              onRestart={() => window.location.reload()}
              seuil={
                diagnostiqueNiveaux?.data[currentLevel]?.seuil ||
                diagnostiqueNiveaux?.data[currentLevel]?.data?.reduce(
                  (acc, cur) => acc + cur?.bareme,
                  0
                ) - 10
              }
              onQuit={handleQuit}
              onCountinue={handleContinueNextLevel}
              loading={addLoading}
              finish={
                diagnostiqueNiveaux?.data.length === currentLevel + 1 ||
                diagnostiqueStore?.notes
                  .filter(
                    (note) =>
                      note?.niveau ===
                      diagnostiqueNiveaux?.data[currentLevel]?.niveau
                  )
                  .reduce((acc, curr) => acc + curr?.note, 0) <
                  diagnostiqueNiveaux?.data[currentLevel]?.seuil
              }
            />
          )}
        </div>
      ) : (
        <WordPage
          data={diagnostiqueNiveaux?.data ?? []}
          current={current}
          currentLevel={currentLevel}
          selected={selected}
          onTranscription={setTranscription}
          handleAddWord={handleAddWord}
          handleRemoveWord={handleRemoveWord}
          resetTranscription={current}
          randomeImage={progressImage[currentLevel]}
          eleve={eleve}
          studentDemo={studentDemo}
          handleGoBack={() => navigation(-1)}
          onTextChange={setTextRecorded}
          textRecorded={textRecorded}
          handleAddTextSpeachWord={handleAddTextSpeachWord}
          selectedTextSpeach={selectedTextSpeach}
          onGetWordSelected={setGetWordSelected}
          // Lettre manquante
          onCheckInput={setSuggestionInputActive}
          suggestionManquante={suggestionManquante}
          suggestionInputActive={suggestionInputActive}
          onPressKebeard={onPressKebeard}
        />
      )}
      {opened ? (
        <div
          className=""
          style={{
            width: "100%",
            height: "100dvh",
            backgroundColor: "rgba(0,0,0,0.1)",
            position: "fixed",
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#ffff",
              width: "100%",
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 10,
            }}
          >
            <MoodEmpty strokeWidth={1} size={58} color={"black"} />
            <span
              style={{
                marginTop: 10,
                color:
                  selected.join(" ") !== data[current].correction_dp.join(" ")
                    ? "red"
                    : "green",
              }}
            >
              {selected.join(" ") !== data[current].correction_dp.join(" ")
                ? "Oups, Vous n'avez pas trouvé"
                : "Bravo! vous avez trouvé"}
            </span>
            <Button
              style={{ width: "100%", marginTop: 40 }}
              radius={"sm"}
              onClick={handleNextExercice}
            >
              {current + 1 === data.length ? "Terminer" : "Suivant"}
            </Button>
          </div>
        </div>
      ) : null}
    </Template>
  );
}

export default ExerciceLecturePage;
