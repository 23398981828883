import useGetUserInfo from "hooks/useGetUserInfo";
// import styles from "assets/styles.module.css";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Drawer from "./Drawer";
// import Drawer from "./Drawer";

/**
 * RequireAuth est un composant de navigation qui permet de protéger les routes qui nécessitent une authentification.
 * Si l'utilisateur est authentifié, le composant rend le contenu de la page, sinon il redirige l'utilisateur vers la page de connexion.
 */
function RequireAuth() {
  const { onBoarding } = useGetUserInfo();

  const location = useLocation();

  return onBoarding ? (
    <>
      <Outlet />
      <Drawer />
    </>
  ) : (
    <Navigate to={"/welcom"} state={{ from: location }} replace />
  );
}

export default RequireAuth;
