import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import APPROUTES from "routes/app.routes";
import RequireAuth from "components/RequireAuth";
import useGetUserInfo from "hooks/useGetUserInfo";
import WithoutAuth from "components/WithoutAuth";

const recursiveRoutes = (routes) => {
  return (
    <>
      {routes.map((route, index) => {
        if (route.subPath) {
          return (
            <Route
              path={route.path}
              element={route.element}
              key={index}
              handle={{
                crumb: () => <Link to={`${route?.path}`}>Route {index}</Link>,
              }}
            >
              {recursiveRoutes(route.subPath)}
            </Route>
          );
        }
        return (
          <Route
            path={route.path}
            element={route.element}
            key={index}
            handle={{
              crumb: () => <Link to={`${route?.path}`}>Route {index}</Link>,
            }}
          />
        );
      })}
    </>
  );
};

function Root() {
  const { userId } = useGetUserInfo();
  return (
    <>
      {userId === 1340 ? (
        <div className="fixed top-0 w-full left-0 px-2 py-[1px] text-[8px] flex justify-center font-bold text-white bg-danger z-50 opacity-90">
          DEMO TEST
        </div>
      ) : null}
      <Routes>
        <Route element={<WithoutAuth />}>
          {recursiveRoutes(APPROUTES.PUBLIC)}
        </Route>

        <Route element={<RequireAuth />}>
          {recursiveRoutes(APPROUTES.PRIVATE)}
        </Route>
      </Routes>
    </>
  );
}

export default Root;
