import axios from "axios";
import PageHeader from "components/PageHeader";
import useGetAssurance from "hooks/useGetAssurance";
import isValidMobilePhone from "hooks/useValidMobilePhone";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddParentMutation } from "redux/features/parentApi";
import {
  // clearParentCredit,
  // getParentForm,
  setNewParentCredit,
  setParentForm,
} from "redux/features/parentSlice";
import { addUserId } from "redux/features/userSlice";
import { NUMBER_VERIFIER_PATH } from "routes/api.path";
import { Loader } from "tabler-icons-react";

function ParentProspectPage() {
  const navigation = useNavigate();

  // const student = useGetStudent();

  // const parentForm = useSelector(getParentForm);
  const dispatch = useDispatch();

  const getAssrence = useGetAssurance();

  const [ADDPARENT, { isLoading }] = useAddParentMutation();
  // const [loginUser, { isLoginLoading }] = useLoginUserMutation();
  const [isVerifyNumberLoading, setIsVerifyNumberLoading] = useState(false);

  const provenance = useGetAssurance();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    // defaultValues: {
    //   fullName: parentForm?.fullName ?? "",
    //   phone: parentForm?.phone ?? "",
    //   email: parentForm?.email ?? "",
    // },
  });

  /**
   * identification et / ou creation d'un parent
   * @param {*} data
   * @returns
   */
  const onSubmit = async (data) => {
    if (!isValidMobilePhone(data?.phone)) {
      return setError("phone", { message: "Numero de telephone invalide!" });
    }

    const formatPhone = data?.phone
      .replace("+221", "")
      .replace(" ", "")
      .split(" ")
      .join("");

    const formatData = {
      ...data,
      phone: formatPhone,
      type: "CONTACT",
      isProspect: true,
      agentCommercialFullName: data?.agentCommercialFullName ?? null,
    };

    // return console.log({ formatData });
    try {
      setIsVerifyNumberLoading(true);
      const resVerifyNumber = await axios.get(
        NUMBER_VERIFIER_PATH + formatPhone + "/"
      );
      setIsVerifyNumberLoading(false);

      // return console.log(resVerifyNumber);
      if (
        resVerifyNumber?.status === 200 &&
        resVerifyNumber?.data?.message === "deja inscrit"
      ) {
        if (resVerifyNumber?.data?.user) {
          if (!resVerifyNumber?.data?.user?.type) {
            return toast.warning("Vous n'êtes pas autorisé à vous connecter.");
          }
          dispatch(setParentForm({ ...resVerifyNumber?.data?.user }));
          dispatch(addUserId(resVerifyNumber?.data?.user?.id));
          // dispatch(
          //   setNewParentCredit({ phone: resVerifyNumber?.data?.user?.phone })
          // );
          navigation("select-enfant", {
            replace: true,
            // state: { nombre: "n" },
          });
          toast.success("Connexion reussi !");
        } else {
          toast.error("Erreur l'or de la verification");
        }
        return;
      }

      const res = await ADDPARENT(
        provenance === "Senassurance"
          ? { ...formatData, provenance }
          : { ...formatData }
      );

      if (!res?.error) {
        dispatch(setParentForm({ ...res?.data?.data }));
        dispatch(addUserId(res?.data?.data.id));
        dispatch(setNewParentCredit({ phone: res?.data?.data?.phone }));
        navigation("form", {
          replace: true,
          state: { nombre: "n" },
        });
        toast.success("Creation de compte reussi !");
        return;
      }
    } catch (error) {
      setIsVerifyNumberLoading(false);
      // console.log({ error });
      if (
        error?.response?.data?.message.includes(
          "l'utilisateur n'existe pas "
        ) &&
        error?.response?.data?.status.includes("failure")
      ) {
        const res = await ADDPARENT(
          provenance === "Senassurance"
            ? { ...formatData, provenance }
            : { ...formatData }
        );

        if (!res?.error) {
          dispatch(setParentForm({ ...res?.data?.data }));
          dispatch(addUserId(res?.data?.data.id));
          dispatch(setNewParentCredit({ phone: res?.data?.data?.phone }));
          navigation("form", {
            replace: true,
            state: { nombre: "n" },
          });
          toast.success("Creation de compte reussi !");
          return;
        }
      }
      setError("root", { message: "une error est survenu" });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#003880",
        color: "#fff",
        height: "100dvh",
        overflowY: "auto",
        paddingTop: 10,
      }}
    >
      <PageHeader title={"Informations du parent"} canShow={false} />
      {/* <RenderData
        data={{}}
        render={() => (
        )}
      /> */}
      <div className="p-4">
        <div className="border-t-[1px] pt-4 border-gray flex flex-col items-center gap-10 mb-6">
          <div className="flex items-center justify-center gap-10 p-2">
            <img src="../../icons/icon1.png" style={{ width: 60 }} alt="logo" />
            {getAssrence === "Senassurance" ? (
              <img
                src="../../icons/senassurance.png"
                style={{ width: 70 }}
                alt="logo"
              />
            ) : null}
          </div>
          {/* <img
                src="../../icons/icon1.png"
                style={{ width: 60 }}
                alt="logo"
              /> */}
          <span className="text-sm flex flex-col items-center r w-full ">
            <p>Avant de commencer</p>
            <p>merci de remplir ce formulaire</p>
          </span>
        </div>
        <form
          className="flex flex-col gap-4 mt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {errors?.root ? (
            <div className="text-danger text-md mt-1">
              {errors?.root?.message}
            </div>
          ) : null}
          <p className="font-bold">Informations du Parent</p>
          <div className="">
            <div className="border-gray border-2 bg-[#fff] rounded-lg p-2">
              <label className="text-sm text-[rgba(0,0,0,0.6)]">
                Prenom et nom
              </label>
              <br />
              <input
                placeholder="ecrire ici..."
                className="focus:outline-none text-[#000] p-1"
                type="text"
                {...register("fullName", {
                  required: "FullName is required",
                })}
              />
            </div>
            {errors?.fullName ? (
              <div className="text-danger text-xs mt-1">
                {errors?.fullName?.message}
              </div>
            ) : null}
          </div>

          <div className="">
            <div className="border-gray border-2 bg-[#fff] rounded-lg p-2">
              <label className="text-sm text-[rgba(0,0,0,0.6)]">
                Numero de telephone
              </label>
              <br />
              <input
                placeholder="ecrire ici..."
                className="focus:outline-none text-[#000] p-1"
                type="tel"
                {...register("phone", { required: "Phone is required" })}
              />
            </div>
            {errors?.phone ? (
              <div className="text-danger text-xs mt-1">
                {errors?.phone?.message}
              </div>
            ) : null}
          </div>

          <div className="">
            <div className="border-gray border-2 bg-[#fff] rounded-lg p-2">
              <label className="text-sm text-[rgba(0,0,0,0.6)]">E-mail</label>
              <br />
              <input
                placeholder="ecrire ici..."
                className="focus:outline-none text-[#000] p-1"
                type="email"
                {...register("email", {
                  // required: "Email Address is required",
                })}
              />
            </div>
            {errors?.email ? (
              <div className="text-danger text-xs mt-1">
                {errors?.email?.message}
              </div>
            ) : null}
          </div>

          {/* <div className="mt-5 w-[100%] bg-white">
            <div>
              <label className="text-gray-500 m-0 p-0 text-xs">Email</label>
              <input
                type="email"
                // value={formData.phone_number}
                // onChange={handleInputChange}
                className="border-gray-300 border w-[100%] rounded-md p-2 mt-1 focus:ring focus:ring-gray-200"
                placeholder="Email"
                {...register("email", {
                  required: true,
                })}
              />
            </div>
          </div> */}

          {getAssrence === "Senassurance" ? (
            <>
              <div className="h-1 w-full bg-[#0003]"></div>

              <div className="">
                <div className="flex flex-col border-gray border-2 bg-[#fff] rounded-lg p-2">
                  <label className="text-sm text-[rgba(0,0,0,0.6)]">
                    Prenom et nom de l'agent commercial
                  </label>
                  <input
                    placeholder="ecrire ici..."
                    className="focus:outline-none text-[#000]"
                    type="text"
                    {...register("agentCommercialFullName", {
                      // required: "agentCommercialFullName is required",
                    })}
                  />
                </div>
                {errors?.agentCommercialFullName ? (
                  <div className="text-danger text-xs mt-1">
                    {errors?.agentCommercialFullName?.message}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}

          <div className="my-8">
            <button
              className="text-[#000] bg-white rounded-full flex items-center justify-center gap-4 p-3 w-full"
              type="submit"
              disabled={isSubmitting}
            >
              {isLoading || isVerifyNumberLoading ? (
                <Loader className="animate-spin" color="#000" />
              ) : null}
              Commencer le diagnostic
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ParentProspectPage;
